import {
  MemberDetailsResponse,
  MemberLoginRequest,
  MemberSignUpRequest,
} from '@apis/MemberAuthApi/MemberAuthApi.types';
import axiosInstance from '@utils/axios';

const MEMBER_AUTH_URL = '/auth';
export default class MemberAuthApi {
  static signUp(signUpRequest: MemberSignUpRequest) {
    const result = axiosInstance.post<MemberDetailsResponse>(`${MEMBER_AUTH_URL}/signup`, signUpRequest, {
      withCredentials: true,
    });

    return result
  }

  static login(loginRequest: MemberLoginRequest) {
    return axiosInstance.post<MemberDetailsResponse>(`${MEMBER_AUTH_URL}/login`, loginRequest, {
      withCredentials: true,
    });
  }

  static refresh(refreshToken: string) {
    return axiosInstance.post<MemberDetailsResponse>(
      `${MEMBER_AUTH_URL}/refresh`,
      { refreshToken },
      { withCredentials: true },
    );
  }

  static me(accessToken: string | null) {
    return axiosInstance.get<MemberDetailsResponse>(`${MEMBER_AUTH_URL}/me`, {
      withCredentials: true,
      headers: { 'AUTHORIZATION': accessToken },
    });
  }

  static logout = (accessToken: string | null) => {
    return axiosInstance.post<MemberDetailsResponse>(`${MEMBER_AUTH_URL}/logout`, {
      withCredentials: true,
      headers: { 'AUTHORIZATION': accessToken },
    });
  };
}
