// ----------------------------------------------------------------------

import { BOTTOM_BAR_MOBILE_HEIGHT, HEADER_MOBILE_HEIGHT } from '@configs';

export default function CssBaseline() {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          margin: 0,
          padding: 0,
          border: 0,
          boxSizing: 'border-box',
          verticalAlign: 'baseline',
          // '-webkit-tap-highlight-color': 'transparent',
          fontFamily:
            "Pretendard, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell','Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        },

        html: {
          // fontSize: '16px',
          userSelect: 'none',
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
          '--safe-area-inset-top': 'env(safe-area-inset-top)',
          '--safe-area-inset-right': 'env(safe-area-inset-right)',
          '--safe-area-inset-bottom': 'env(safe-area-inset-bottom)',
          '--safe-area-inset-left': 'env(safe-area-inset-left)',

          textSizeAdjust: '100%',
          // 스크롤바 제거
          // msOverflowStyle: 'none', // IE, Edge
          // scrollbarWidth: 'none', // Firefox
        },
        // ios스크롤 제거 (안되네)
        // '::-webkit-scrollbar': {
        //   display: 'none',
        //   width: 0,
        //   height: 0,
        //   background: 'transparent',
        //   webkitAppearance: 'none',
        // },
        body: {
          width: '100%',
          height: '100%',

          fontWeight: 400,
          lineHeight: 1.2,
          letterSpacing: '-0.02em',
        },
        a: {
          textDecoration: 'none',
        },

        '.SnackbarContainer-bottom': {
          bottom: `${BOTTOM_BAR_MOBILE_HEIGHT}px!important`,
        },

        '.SnackbarContainer-top': {
          top: `${HEADER_MOBILE_HEIGHT}px!important`,
        },
        '.SnackbarItem-message': {
          padding: '4px 0',
        },
        '.MuiSnackbar-anchorOriginBottomCenter': {
          bottom: `${BOTTOM_BAR_MOBILE_HEIGHT + 16}px!important`,
        },
        '.MuiSnackbar-root': {
          // left: '50%!important',
          left: '16px!important',
          right: '16px!important',
          maxWidth: 'calc(100% - 32px)!important',
        },
        '.MuiSnackbarContent-root': {
          backgroundColor: 'rgb(50,50,50)!important',
          fontSize: '0.875rem!important',
          lineHeight: '1.43!important',
          letterSpacing: '0.01em!important',
          borderRadius: '4px!important',
          padding: '6px 16px!important',
        },
        '.MuiSnackbarContent-message': {
          padding: '8px 0!important',
          fontWeight: 600,
        },

        '#__next': {
          width: '100%',
          height: '100%',
        }, // List
        '& ul, & ol': {
          paddingLeft: 24,
          '& li': {
            lineHeight: 1.5,
          },
        },
        "[type='submit']": {
          backgroundColor: 'none',
        },
      },
    },
  };
}
