import React, { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import modalAtom from '@recoil/modal';
import { MODAL_COMPONENTS } from '@components/GlobalModal/modalComponents';
import { ErrorBoundary } from 'react-error-boundary';

function GlobalModal() {
  const modalMap = useRecoilValue(modalAtom);

  const renderComponent = (modalName: string, props: any, open: boolean) => {
    const ModalComponent = MODAL_COMPONENTS[modalName];
    return (
      <ErrorBoundary fallback={null} key={modalName}>
        <Suspense fallback={null}>{open && <ModalComponent key={modalName} {...props} open={open} />}</Suspense>
      </ErrorBoundary>
    );
  };

  return <>{Object.values(modalMap).map((modal) => renderComponent(modal.name, modal.props, modal.open))}</>;
}

export default GlobalModal;
