import { useSetRecoilState } from 'recoil';
import userAtom from '@recoil/user/atom';
import { MemberDetailsResponse } from '@apis/MemberAuthApi/MemberAuthApi.types';
import { deleteBearerHeader, removeRefreshToken, saveRefreshToken, setBearerHeader } from '@utils/authUtils';

export const useUserReducer = () => {
  const setUser = useSetRecoilState(userAtom);

  const authorize = (response: MemberDetailsResponse) => {
    setBearerHeader(response.accessToken);
    saveRefreshToken(response.refreshToken);
    setUser({
      loginId: response.loginId,
      id: response.id,
      name: response.name,
      phoneNumber: response.phoneNumber,
      accessToken: response.accessToken,
      refreshToken: response.refreshToken,
      isInitialized: true,
      isAuthenticated: true,
    });
  };
  const unauthorize = (clearToken = true) => {
    setUser({
      loginId: null,
      id: null,
      name: null,
      phoneNumber: null,
      accessToken: null,
      refreshToken: null,
      isInitialized: true,
      isAuthenticated: false,
    });
    clearToken && removeRefreshToken();
    deleteBearerHeader();
  };

  return {
    authorize,
    unauthorize,
  };
};
