import { createContext, ReactNode } from 'react';
import { SettingsContextProps, SettingsValueProps } from '@/@types/settings';
import useLocalStorage from '@hooks/useLocalStorage';

export const defaultSettings = {
  themeMode: 'light',
} as SettingsValueProps;

const initialState: SettingsContextProps = {
  ...defaultSettings,
  onToggleMode: () => {},
};

const SettingsContext = createContext(initialState);

type Props = {
  children: ReactNode;
};

function SettingsProvider({ children }: Props) {
  const [settings, setSettings] = useLocalStorage('settings', {
    ...defaultSettings,
  });

  const onToggleMode = () => {
    setSettings({
      ...settings,
      themeMode: settings.themeMode === 'light' ? 'dark' : 'light',
    });
  };

  const onResetSetting = () => {
    setSettings({
      ...defaultSettings,
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        // Mode
        onToggleMode,
        // Reset Setting
        onResetSetting,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
