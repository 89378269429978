import { atom } from 'recoil';

export interface UserDetails {
  id: number | null;
  loginId: string | null;
  name: string | null;
  phoneNumber: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean;
  isInitialized: boolean;
}

const userAtom = atom({
  key: 'user',
  default: {
    id: null,
    loginId: null,
    name: null,
    phoneNumber: null,
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
    isInitialized: false,
  } as UserDetails,
});

export default userAtom;
