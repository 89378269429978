import React from 'react';
import userAtom from '@recoil/user/atom';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import Loading from '@components/Loading/Loading';

type Props = {
  children: React.ReactNode;
};

const AuthFilter = ({ children }: Props) => {
  const router = useRouter();
  const { isInitialized, isAuthenticated } = useRecoilValue(userAtom);

  if (!isInitialized) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    if (!['/login', '/signup'].includes(router.pathname)) {
      router.push('/login');
    }
  }

  return <>{children}</>;
};

export default AuthFilter;
