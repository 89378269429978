import React, { useEffect } from 'react';
import MemberAuthApi from '@apis/MemberAuthApi/MemberAuthApi';
import { useRecoilState } from 'recoil';
import userAtom, { useUserReducer } from '@recoil/user';
import { getRefreshToken } from '@utils/authUtils';
import axios from 'axios';

const useAuth = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const { authorize, unauthorize } = useUserReducer();

  useEffect(() => {
    const initialize = async () => {
      console.log('isInitialize', user?.isInitialized);
      if (user?.isInitialized) {
        console.log('user initialized');
        return;
      }
      try {
        const refreshToken = getRefreshToken();
        if (refreshToken) {
          const { data } = await MemberAuthApi.refresh(refreshToken);
          authorize(data);
        } else {
          unauthorize();
        }
      } catch (error) {
        console.log('refresh error', error);
        if (axios.isAxiosError(error) && error.status === 400) {
          unauthorize();
        } else {
          unauthorize(false);
        }
      }
    };

    initialize();
  }, [user?.isInitialized]);
};

export default useAuth;
