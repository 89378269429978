import { EmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import * as React from 'react';
import { ReactElement, ReactNode, Suspense, useRef } from 'react';
import Head from 'next/head';
import { SnackbarProvider } from 'notistack';
import Script from 'next/script';
import ThemeProvider from '@/theme';
import withProvider from '@components/withProvider/withProvider';
import useAuth from '@hooks/useAuth';
import AuthFilter from '@components/AuthProvider/AuthFilter';
import { QueryClient } from '@tanstack/query-core';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { AnimatePresence } from 'framer-motion';
import GlobalModal from '@components/GlobalModal/GlobalModal';

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}

declare global {
  interface Window {
    Kakao: any;
    kakao: any;
  }
}

function MyApp(props: MyAppProps) {
  const queryClientRef = useRef<QueryClient>();
  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: true,
          refetchOnMount: true,
          refetchOnReconnect: true,
          // retry: false,
          // staleTime: 1 * 60 * 1000,
        },
      },
    });
  }
  useAuth();

  const { Component, pageProps } = props;
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, user-scalable=no, maximum-scale=1, viewport-fit=cover"
        />
      </Head>
      <Script
        src={`https://cdn.jsdelivr.net/npm/@stomp/stompjs@5.0.0/bundles/stomp.umd.min.js`}
        strategy="beforeInteractive"
      />
      {/* Naver map */}
      <Script
        type="text/javascript"
        src="https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=aq9iu19rdn&submodules=geocoder"
        strategy="beforeInteractive"
      />
      <SnackbarProvider
        maxSnack={1}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        autoHideDuration={1500}
        transitionDuration={{ exit: 100, enter: 200 }}
      >
        <QueryClientProvider client={queryClientRef.current}>
          <ThemeProvider>
            <CssBaseline />

            <ErrorBoundary fallback={null}>
              <Suspense fallback={null}>
                <AuthFilter>
                  {getLayout(<Component {...pageProps} />)}

                  <AnimatePresence>
                    <GlobalModal />
                  </AnimatePresence>
                </AuthFilter>
              </Suspense>
            </ErrorBoundary>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </ThemeProvider>
        </QueryClientProvider>
      </SnackbarProvider>
    </>
  );
}

export default withProvider(MyApp);
