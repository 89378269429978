import { atom } from 'recoil';
import { MODAL_COMPONENTS } from '@components/GlobalModal/modalComponents';

export interface ModalAtom {
  open: boolean;
  name: string;
  props?: any;
}

export interface ModalMapAtom {
  [name: string]: ModalAtom;
}

const getDefault = (): ModalMapAtom => {
  const temp: ModalMapAtom = {};
  Object.keys(MODAL_COMPONENTS).forEach((modal) => {
    if (!temp[modal]) {
      temp[modal] = {
        open: false,
        name: modal,
      };
    }
  });

  return temp;
};
const modalMapAtom = atom<ModalMapAtom>({
  key: 'modalMapAtom',
  default: { ...getDefault() },
});

export default modalMapAtom;
