import React from 'react';
import { RecoilRoot } from 'recoil';
import { NextComponentType, NextPageContext } from 'next/dist/shared/lib/utils';
import { SettingsProvider } from '@/settings/SettingsContext';

// eslint-disable-next-line @typescript-eslint/ban-types
const withProvider = <P,>(Page: NextComponentType<NextPageContext, {}, P>) =>
  class extends React.Component<P> {
    static getInitialProps(ctx: NextPageContext) {
      if (Page.getInitialProps) {
        return Page.getInitialProps(ctx);
      }
      return {};
    }

    render() {
      return (
        <RecoilRoot>
          <SettingsProvider>
            <Page {...this.props} />
          </SettingsProvider>
        </RecoilRoot>
      );
    }
  };

export default withProvider;
