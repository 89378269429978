import { DOMAIN_AUTH_HEADER, ENV } from '@configs';
import axiosInstance from '@utils/axios';
//

export const setBearerHeader = (accessToken: string | null) => {
  if (accessToken) {
    axiosInstance.defaults.headers[DOMAIN_AUTH_HEADER] = accessToken;
  } else {
    delete axiosInstance.defaults.headers[DOMAIN_AUTH_HEADER];
  }
};
export const deleteBearerHeader = () => {
  delete axiosInstance.defaults.headers[DOMAIN_AUTH_HEADER];
};

export const saveRefreshToken = (refreshToken: string | null) => {
  if (refreshToken) {
    if (ENV === 'PRODUCTION') {
      localStorage.setItem('refreshToken', refreshToken);
    } else if (ENV === 'DEVELOPMENT') {
      localStorage.setItem('refreshTokenDev', refreshToken);
    } else {
      localStorage.setItem('refreshTokenLocal', refreshToken);
    }
  } else {
    if (ENV === 'PRODUCTION') {
      localStorage.removeItem('refreshToken');
    } else if (ENV === 'DEVELOPMENT') {
      localStorage.removeItem('refreshTokenDev');
    } else {
      localStorage.removeItem('refreshTokenLocal');
    }
  }
};

export const getRefreshToken = (): string | null => {
  if (ENV === 'PRODUCTION') {
    return localStorage.getItem('refreshToken');
  } else if (ENV === 'DEVELOPMENT') {
    return localStorage.getItem('refreshTokenDev');
  } else {
    return localStorage.getItem('refreshTokenLocal');
  }
};

export const removeRefreshToken = () => {
  if (ENV === 'PRODUCTION') {
    return localStorage.removeItem('refreshToken');
  } else if (ENV === 'DEVELOPMENT') {
    return localStorage.removeItem('refreshTokenDev');
  } else {
    return localStorage.removeItem('refreshTokenLocal');
  }
};
