import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/material';

// ----------------------------------------------------------------------

export default function Tabs(theme: Theme) {
  return {
    MuiTabs: {
      styleOverrides: {
        root: {
          height: 28,
          minHeight: 28,
          overflow: 'visible',
          transition: 'none',
        },
        indicator: {
          height: 28,
          width: 70,
          minHeight: 28,
          borderRadius: 14,
          border: 'none',
          backgroundColor: theme.palette.doeat.colors.yellow,
          // boxShadow: `0px 2px 10px ${theme.palette.doeat.colors.yellow}`,
        },
        scrollableX: {
          overflow: 'visible !important',
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          transition: 'none',

          padding: 0,
          height: 28,
          minHeight: 28,
          borderRadius: 14,
          // transition: 'color .2s',
          textTransform: 'capitalize',
          overflow: 'visible',
          fontSize: 16,
          letterSpacing: '-0.02em',
          fontWeight: 400,
          color: theme.palette.doeat.colors.black,

          '&.MuiButtonBase-root': {
            minWidth: 'auto',
            paddingLeft: 10,
            paddingRight: 10,
            marginRight: 4,
            transition: 'none',
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            color: theme.palette.doeat.colors.white,
            zIndex: 5,
            fontWeight: 600,
            transition: 'none',
          },
          '&:hover': {
            color: theme.palette.doeat.colors.black,
            backgroundColor: alpha(theme.palette.doeat.colors.yellow, 0.05),
          },
          '& .MuiTouchRipple-root': {
            transition: 'none',
          },
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          width: 48,
          borderRadius: '50%',
        },
      },
    },
  };
}
