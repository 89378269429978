import { Oval, RotatingLines } from 'react-loader-spinner';
import React from 'react';

interface LoadingProps {
  type?:
    | 'rotating'
    // | 'tailspin'
    // | 'dots'
    | 'oval';
}

function Loading({ type }: LoadingProps) {
  const render = () => {
    switch (type) {
      case 'rotating':
        return <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.9" width="36" visible={true} />;
      // case 'tailspin':
      //   return <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.9" width="36" visible={true} />;
      // case 'dots':
      //   return <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.9" width="36" visible={true} />;
      case 'oval':
        return <Oval color="#3e71ff" secondaryColor="#1890ff" strokeWidth="5" width="36" visible={true} />;
      default:
        return <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.9" width="36" visible={true} />;
    }
  };

  return (
    <div style={{ display: 'flex', width: '100%', minHeight: '100px', justifyContent: 'center', alignItems: 'center' }}>
      {render()}
    </div>
  );
}

export default Loading;
