export const ENV = process.env.NEXT_PUBLIC_ENV;
export const SERVER_URL = process.env.NEXT_PUBLIC_SERVER_URL;
export const FRONT_URL = process.env.NEXT_PUBLIC_FRONT_URL;

export const DOMAIN_AUTH_HEADER = 'AUTHORIZATION';

/**
 * layout
 */

export const HEADER_MOBILE_HEIGHT = 60;
export const HEADER_DESKTOP_HEIGHT = 60;
export const BOTTOM_BAR_MOBILE_HEIGHT = 100;
export const CHAT_BOTTOM_BAR = 60;
export const CHAT_BOTTOM_BAR_WITH_REPLY = 130;
// export const BOTTOM_BAR_DESKTOP_HEIGHT = 100;
export const STORE_INTRO_CARD_HEIGHT = 160;
export const STORE_HEAD_IMAGE_HEIGHT = 250;
