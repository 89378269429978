import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function ButtonBase(theme: Theme) {
  return {
    MuiButtonBase: {
      defaultProps: {
        // The props to apply
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
  };
}
